<template>
  <div>
    <b-modal
      v-model="showModalUpdate"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      :title="ticket.subject"
      lazy
      static
      @hidden="$emit('close')"
    >
      <section class="chat-app-window">
        <div class="chat-app-window chats">
          <div
            v-for="(msgGrp, index) in ticket.messages"
            :key="msgGrp.senderId+String(index)"
            class="chat"
            :class="{'chat-left': msgGrp.sender.name !== ticket.messages[0].sender.name}"
          >
            <div class="chat-body">
              <div
                class="chat-content"
                :style="msgGrp.source.type === 'internal-note' ? 'background-color: yellow' : ''"
              >
                <p v-if="msgGrp.source.type === 'internal-note'"><strong><u>Note:</u></strong></p>
                <p v-if="msgGrp.stripped_html" v-html="msgGrp.stripped_html" />
                <p v-else v-html="msgGrp.body_html" />
                <br v-if="msgGrp.sender.name === ticket.messages[0].sender.name">
                <small class="float-right mb-25 chat-time">{{ msgGrp.sender.name }} - {{ $moment(new Date(msgGrp.created_datetime)).format('HH:mm - DD/MM/YYYY') }} {{ msgGrp.isEdited ? ' - Edited' : '' }}</small>
              </div>
            </div>
          </div>
        </div>
      </section>
    </b-modal>
  </div>
</template>

<script>
import {
  BModal,
} from 'bootstrap-vue'

export default {
  components: {
    // BAvatar,
    BModal,
  },
  props: {
    ticket: {
      type: Object,
      required: true,
    },
    // profileUserAvatar: {
    //   type: String,
    //   required: true,
    // },
  },
  data() {
    return {
      message: null,
      showModalUpdate: true,
    }
  },
  methods: {
    openModal(msg) {
      this.message = msg
      this.showModalUpdate = true
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-chat.scss";
@import "~@core/scss/base/pages/app-chat-list.scss";
</style>
