<template>
  <div>
    <b-modal
      v-model="openModal"
      ok-only
      ok-title="Accept"
      centered
      size="lg"
      :hide-footer="true"
      title="Problème défectueux"
    >
      <b-row class="mb-2">
        <b-col
          cols="6"
        >
          <b-form-group
            label="Sélection du produit"
          >
            <v-select
              v-model="product"
              :options="order.line_items.map(i => ({...i, composeName: `${i.name} - ${i.sku}`}))"
              label="composeName"
              transition=""
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="product" class="mb-2">
        <b-col
          cols="6"
        >
          <b-form-group
            label="Type de problème"
          >
            <v-select
              v-model="type"
              :options="['BROKEN', 'DAMAGED', 'MISSING']"
              label="name"
              transition=""
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group
            label="Lien conversation Gorgias"
          >
            <b-form-input
              v-model="gorgiasLink"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="product" class="mb-2">
        <b-col
          cols="12"
        >
          <b-form-group
            label="Photos problème client"
          >
            <img v-for="(src, key) of images" :key="key" :src="src" style="width: 120px; height: 120px;" />
            <b-col
              cols="6"
            >
              <b-form-file
                v-model="file"
                placeholder="Choisir un fichier ou glisser..."
                drop-placeholder="Drop fichier ici..."
                accept=".jpg, .png"
                class="mt-2"
              />
              <b-button
                v-if="file"
                class="mt-1"
                variant="primary"
                @click="upload"
              >
                Ajouter l'image
              </b-button>
            </b-col>
          </b-form-group>
        </b-col>
        <b-col
          cols="12"
        >
          <b-form-group
            label="Commentaire"
          >
            <BFormTextarea
              v-model="description"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-row v-if="product" class="mb-2">
        <!-- <b-col
          cols="3"
        >
          <b-form-group
            label="Envoi"
          >
            <v-select
              v-model="sendCheck"
              :options="['Oui', 'Non']"
              transition=""
            />
          </b-form-group>
        </b-col> -->

        <b-col
          cols="6"
        >
          <b-form-group
            label="Geste commercial"
          >
            <b-form-input v-model="gestCo" />
          </b-form-group>
        </b-col>
      </b-row>
      <div v-if="product">
        <hr class="v-divider v-theme--light" aria-orientation="horizontal" role="separator"/>
        <b-row
          v-for="(productR, key) of productResend"
          :key="key"
          class="mb-2"
        >
          <b-col
            v-if="productR"
            cols="6"
          >
            <b-form-group
              label="Produit à renvoyer"
            >
              <v-select
                v-model="productR.product"
                :options="[product, {name: 'Housse'},{ name: 'Pieds'},{ name: 'Tête de lit'}]"
                label="name"
                transition=""
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productR"
            cols="2"
          >
            <b-form-group
              label="Avec retour"
            >
              <b-form-checkbox
                v-model="productR.withReturn"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productR"
            cols="2"
          >
            <b-form-group
              label="Avec envoi"
            >
              <b-form-checkbox
                v-model="productR.withSend"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-col
          cols="12"
        >
          <b-button
            class="mt-1"
            variant="primary"
            @click="productResend.push({product: null, withReturn: false, withSend: false})"
          >
            Ajouter un produit
          </b-button>
        </b-col>
        <b-col
          cols="12"
        >
          <hr class="v-divider v-theme--light" aria-orientation="horizontal" role="separator"/>
          <b-button
            class="mt-1"
            variant="primary"
          >
            Créer le défectueux
          </b-button>
        </b-col>
      </div>
    </b-modal>
    <b-button variant="primary" @click="openModal = !openModal">
      <feather-icon icon="AlertOctagonIcon" class="mr-50" />
      <span class="align-middle">Défectueux</span>
    </b-button>
    <b-button v-if="order.system && order.system.ship && order.system.ship.url" variant="primary" class="ml-2">
      <feather-icon icon="DownloadIcon" class="mr-50" />
        <span class="align-middle" @click="openShippingTicket(order.system.ship.url)">Ouvrir étiquette</span>
    </b-button>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, VBToggle, BModal, BFormGroup, BFormInput, BFormFile, BFormTextarea,
  BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BModal,
    BFormGroup,
    BFormFile,
    BFormTextarea,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      openModal: false,
      loading: false,
      product: null,
      images: [],
      file: null,
      productResend: [{ product: null, withReturn: false, withSend: false }],
    }
  },
  methods: {
    async upload(f) {
      console.log(this.selectedProduct)
      if (!this.file) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez renseigner tous les champs.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        console.log(f)
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          const { url } = await this.$http.post('/admin/upload-image', form)
          this.images.push(url)
          this.file = null
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Votre image a bien été sauvegardée.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        } catch (err) {
          console.log(err)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
