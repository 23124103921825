<template>
  <b-sidebar
    :id="id"
    :ref="id"
    sidebar-class="sidebar-lg"
    bg-variant="white"
    shadow
    backdrop
    no-header
    right
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Modifier {{ title }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />

      </div>

      <!-- Body -->
      <b-form
        v-if="customer"
        class="p-2"
        @submit.prevent
      >

        <b-form-group
          label="Prénom"
          label-for="first_name"
        >
          <b-form-input
            id="first_name"
            v-model="customer.first_name"
            trim
            placeholder="Prénom"
          />
        </b-form-group>

        <b-form-group
          label="Nom"
          label-for="last_name"
        >
          <b-form-input
            id="last_name"
            v-model="customer.last_name"
            trim
            placeholder="Nom"
          />
        </b-form-group>

        <b-form-group
          label="Adresse"
          label-for="address_1"
        >
          <b-form-input
            id="address_1"
            v-model="customer.address_1"
            trim
            placeholder="Adresse 1"
          />
        </b-form-group>

        <!-- Email -->
        <b-form-group
          label="Adresse 2"
          label-for="address_2"
        >
          <b-form-input
            id="address_2"
            v-model="customer.address_2"
            trim
            placeholder="Adresse 2"
          />
        </b-form-group>

        <b-form-group
          label="Adresse 2"
          label-for="company"
        >
          <b-form-input
            id="company"
            v-model="customer.company"
            trim
            placeholder="Société"
          />
        </b-form-group>

        <!-- Address -->
        <b-form-group
          label="Code Postal"
          label-for="postcode"
        >
          <b-form-input
            id="postcode"
            v-model="customer.postcode"
            placeholder="221B Baker Street"
            trim
          />
        </b-form-group>

        <b-form-group
          label="Email"
          label-for="email"
        >
          <b-form-input
            id="email"
            v-model="customer.email"
            placeholder="test@test.com"
            trim
          />
        </b-form-group>

        <!-- Contact -->
        <b-form-group
          label="Ville"
          label-for="city"
        >
          <b-form-input
            id="city"
            v-model="customer.city"
            trim
          />
        </b-form-group>

        <b-form-group
          label="Région"
          label-for="region"
        >
          <b-form-input
            id="region"
            v-model="customer.state"
            trim
          />
        </b-form-group>

        <b-form-group
          label="Pays"
          label-for="country"
        >
          <b-form-input
            id="country"
            v-model="customer.country"
            trim
          />
        </b-form-group>

        <b-form-group
          v-if="type === 'billing'"
          label="Téléphone"
          label-for="phone"
        >
          <!-- <b-form-input
            id="phone"
            v-model="customer.phone"
            trim
          /> -->
          <vue-tel-input
            v-model="customer.phone"
            class="kipli__phone"
          />
          <span>ATTENTION ! Le format doit absolument être +39 3 39 6 33 34 35</span>
        </b-form-group>

        <!-- Form Actions -->
        <div class="d-flex mt-2">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mr-2"
            type="submit"
            :disabled="loading"
            @click="updateOrder(type)"
          >
            <b-spinner
              v-if="loading"
              small
            />
            Enregistrer
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            variant="outline-secondary"
            @click="hide"
          >
            Annuler
          </b-button>
        </div>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar, BForm, BFormGroup, BFormInput, BButton, BSpinner,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BSidebar, BForm, BFormGroup, BFormInput, BButton, BSpinner,
  },
  directives: {
    Ripple,
  },
  props: {
    id: {
      type: String,
      default: 'user',
    },
    type: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      default: '',
    },
    user: {
      type: Object,
      required: true,
    },
    isNew: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      customer: null,
      loading: false,
    }
  },
  mounted() {
    const customer = {
      last_name: this.user?.last_name || '',
      first_name: this.user?.first_name || '',
      address_1: this.user?.address_1 || '',
      address_2: this.user?.address_2 || '',
      company: this.user?.company || '',
      postcode: this.user?.postcode || '',
      email: this.user?.email || '',
      city: this.user?.city || '',
      state: this.user?.state || '',
      country: this.user?.country || '',
      phone: this.user?.phone || '',
    }

    this.customer = customer
  },
  methods: {
    async updateOrder(type) {
      try {
        if (this.isNew) {
          this.$emit('update-new', this.customer)
        } else {
          this.loading = true
          const { order } = await this.$http.put(`/admin/order/${this.$route.params.id}`, { type, data: this.customer })
          this.order = order
          this.$refs[this.id].hide()
          this.$emit('update')
        }
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    setPhone(formatted, e, v) {
      // if (countryCallingCode) this.data.form[key].phone_code = countryCallingCode
      // if (number) this.data[key].phone = number
      console.log(formatted, e, v)
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
