<template>
  <div>
    <b-modal
      v-model="openModal"
      ok-only
      ok-title="Accept"
      centered
      size="xl"
      :hide-footer="true"
      title="Retour produit"
    >
      <b-row
        v-for="(item, key) of returnItems"
        :key="key"
        class="mb-2"
      >
        <b-col
          cols="1"
        >
          <img
            v-if="order.products && item && order.products.find(i => i._id === item.product_id)"
            :src="order.products.find(i => i._id === item.product_id).src"
            style="width: 50px; object-fit: cover;"
            class="rounded-md"
          >
        </b-col>
        <b-col
          cols="3"
        >
          <p class="small mb-0">
            {{ item.name }}
          </p>
          <span class="small"><strong><u>SKU:</u></strong> {{ item.sku }}</span>
          <br>
          <span
            v-for="(meta, keyI) of item.meta_data"
            :key="keyI"
            class="small"
          >
            <span v-if="meta.value"><strong><u>{{ meta.key }}:</u></strong> {{ meta.value }}</span>
            <br>
          </span>
        </b-col>
        <b-col
          cols="3"
        >
          <b-form-group
            class="small"
            label="Problème"
          >
            <v-select
              v-model="item.returnType"
              :options="['Ne correspond pas aux infos du site (visuel, couleur, fermeté, chaleur, …)', 'Mauvaise taille', 'Inconfortable', 'Trop lourd (couette - matelas)', 'Le client a changé d’avis', 'Article défectueux', 'Délais de livraison trop long', 'Colis perdu', 'Autres']"
              label="name"
              transition=""
            />
          </b-form-group>
        </b-col>

        <b-col
          cols="1"
        >
          <b-form-group
            class="small"
            label="Qty"
          >
            <b-form-input
              v-model="item.quantity"
              type="number"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="2"
        >
          <b-form-group
            class="small"
            label="Shipper"
          >
            <v-select
              v-model="item.shipperReturn"
              :options="carrier"
              label="name"
              transition=""
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="1"
        >
          <feather-icon
            icon="TrashIcon"
            class="ml-5 text-primary"
            style="cursor: pointer;"
            size="20"
            @click="returnItems.splice(key, 1)"
          />
        </b-col>
      </b-row>
      <b-row
        class="mb-2"
      >
        <b-col
          cols="6"
        >
          <b-form-group
            label="Frais de retour (€)"
          >
            <b-form-input
              v-model="frais"
            />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group
            label="Geste commercial ( description )"
          >
            <b-form-input v-model="gestCo" />
          </b-form-group>
        </b-col>
        <b-col
          cols="6"
        >
          <b-form-group
            label="Montant remboursement (€)"
          >
            <b-form-input v-model="montantRefund" />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <b-row
        class="mb-2"
      >
      <b-col
          cols="6"
        >
          <b-form-group
            label="Photos problème client"
          >
            <img
              v-for="(src, key) of images"
              :key="key"
              :src="src"
              style="width: 120px; height: 120px;"
            >
            <b-col
              cols="12"
            >
              <b-form-file
                v-model="file"
                placeholder="Choisir un fichier ou glisser..."
                drop-placeholder="Drop fichier ici..."
                accept=".jpg, .png"
                class="mt-2"
              />
              <b-button
                v-if="file"
                class="mt-1"
                variant="primary"
                @click="upload"
              >
                Ajouter l'image
              </b-button>
            </b-col>
          </b-form-group>
        </b-col>
      </b-row> -->
      <b-row
        class="mb-2"
      >

        <b-col
          cols="12"
        >
          <b-form-group
            label="Commentaire"
          >
            <BFormTextarea
              v-model="description"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <!-- <div v-if="product">
        <hr
          class="v-divider v-theme--light"
          aria-orientation="horizontal"
          role="separator"
        >
        <b-row
          v-for="(productR, key) of productResend"
          :key="key"
          class="mb-2"
        >
          <b-col
            v-if="productR"
            cols="6"
          >
            <b-form-group
              label="Produit à renvoyer"
            >
              <v-select
                v-model="productR.product"
                :options="[product, {name: 'Housse'},{ name: 'Pieds'},{ name: 'Tête de lit'}]"
                label="name"
                transition=""
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productR"
            cols="2"
          >
            <b-form-group
              label="Avec retour"
            >
              <b-form-checkbox
                v-model="productR.withReturn"
                switch
              />
            </b-form-group>
          </b-col>
          <b-col
            v-if="productR"
            cols="2"
          >
            <b-form-group
              label="Avec envoi"
            >
              <b-form-checkbox
                v-model="productR.withSend"
                switch
              />
            </b-form-group>
          </b-col>
        </b-row>
        <b-col
          cols="12"
        >
          <b-button
            class="mt-1"
            variant="primary"
            @click="productResend.push({product: null, withReturn: false, withSend: false})"
          >
            Ajouter un produit
          </b-button>
        </b-col>
        <b-col
          cols="12"
        >
          <hr
            class="v-divider v-theme--light"
            aria-orientation="horizontal"
            role="separator"
          >
          <b-button
            class="mt-1"
            variant="primary"
          >
            Créer le défectueux
          </b-button>
        </b-col>
      </div> -->
      <b-button
        variant="primary"
        :disabled="loading"
        @click="generateReturn"
      >
        <feather-icon
          icon="AlertOctagonIcon"
          class="mr-50"
        />
        <span class="align-middle">Créer le retour</span>
      </b-button>
    </b-modal>
    <b-button
      variant="primary"
      @click="openModal = !openModal"
    >
      <feather-icon
        icon="CornerUpLeftIcon"
        class="mr-50"
      />
      <span class="align-middle">Retour</span>
    </b-button>
  </div>
</template>

<script>
import {
  BRow, BCol, BButton, VBToggle, BModal, BFormGroup, BFormInput, BFormTextarea,
  // BFormCheckbox,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'

export default {
  directives: {
    Ripple,
    'b-toggle': VBToggle,
  },
  components: {
    vSelect,
    BRow,
    BCol,
    BButton,
    BFormInput,
    BModal,
    BFormGroup,
    // BFormFile,
    BFormTextarea,
    // BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    ToastificationContentVue,
  },
  props: {
    order: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      returnItems: null,
      openModal: false,
      loading: false,
      product: null,
      images: [],
      file: null,
      frais: '',
      description: '',
      gestCo: '',
      montantRefund: 0,
      productResend: [{ product: null, withReturn: false, withSend: false }],
      toAdress:
        {
          name: 'KIPLI C/O COTE D\'AZUR ROUTAGE',
          company: 'KIPLI C/O COTE D\'AZUR ROUTAGE',
          street1: 'ZI 1 ERE AVENUE',
          street2: '',
          city: 'CARROS CEDEX',
          state: 'FR',
          zip: '06517',
          country: 'FR',
          phone: '0493081050',
        },
      car: {
        name: 'Colissimo',
        CarrierName: 'Colissimo',
        CarrierID: 11521,
        CarrierService: 'Standard',
      },
      carrier: [
        {
          name: 'Autres',
          CarrierName: 'Autres',
          CarrierID: 9999,
          CarrierService: 'Autres',
        },
        {
          name: 'Colissimo',
          CarrierName: 'Colissimo',
          CarrierID: 11521,
          CarrierService: 'Standard',
        }],
      shipMapped: {
        note: '',
        from_address: {
          name: 'KIPLI C/O COTE D\'AZUR ROUTAGE',
          company: 'KIPLI C/O COTE D\'AZUR ROUTAGE',
          street1: 'ZI 1 ERE AVENUE',
          street2: '',
          city: 'CARROS CEDEX',
          state: 'FR',
          zip: '06517',
          country: 'FR',
          phone: '0493081050',
        },
        to_address: {
          name: `${this.order?.shipping?.first_name} ${this.order?.shipping?.last_name}`,
          company: `${this.order?.shipping?.first_name} ${this.order?.shipping?.last_name}`,
          street1: this.order?.shipping?.address_1,
          street2: this.order?.shipping?.address_2,
          city: this.order?.shipping?.city,
          state: this.order?.shipping?.state,
          zip: this.order?.shipping?.postcode,
          country: this.order?.shipping?.country,
          phone: '+33634338148',
          email: this.order?.billing?.email,
        },
        ContentDescription: this.returnItems?.filter(item => item.dimension)?.map(item => item?.sku)?.join('/'),
      },
    }
  },
  mounted() {
    this.returnItems = this.order.itemsLogistics.map(i => ({ ...i, quantity: 1 }))
  },
  methods: {
    async generateReturn() {
      try {
        this.loading = true
        this.returnItems = this.returnItems.map(i => ({
          ...i, gesteCo: this.gestCo, description: this.description, frais: this.frais, montantRefund: this.montantRefund,
        }))
        this.shipMapped = {
          ...this.shipMapped,
        }
        await this.$http.post(
          `/admin/shippypro/${this.order.number}/return`, { ...this.shipMapped, ...this.car, items: this.returnItems },
        )
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Retour créé',
            icon: 'EditIcon',
            variant: 'success',
          },
        })
        this.openModal = false
      } catch (err) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Il y a eu une erreur',
            icon: 'EditIcon',
            variant: 'danger',
          },
        })
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    async upload(f) {
      console.log(this.selectedProduct)
      if (!this.file) {
        this.$toast({
          component: ToastificationContentVue,
          props: {
            title: 'Vous devez renseigner tous les champs.',
            icon: 'EditIcon',
            variant: 'warning',
          },
        })
      } else {
        console.log(f)
        setTimeout(() => {}, 2000)
        const form = new FormData()
        form.append('file', this.file, this.file.name)
        try {
          const { url } = await this.$http.post('/admin/upload-image', form)
          this.images.push(url)
          this.file = null
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Votre image a bien été sauvegardée.',
              icon: 'EditIcon',
              variant: 'success',
            },
          })
        } catch (err) {
          console.log(err)
        }
      }
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
